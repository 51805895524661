import { Text } from 'tendo-ui'
import styled from 'styled-components'

import FullWidthSpacer from 'components/FullWidthSpacer'

export const Wrapper = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.space100};
  padding-top: 68px;
`

export const Content = styled(FullWidthSpacer).attrs({
  direction: 'vertical',
  size: 30,
})`
  padding: 30px 16px;
  align-items: center;
  display: flex;
  width: 100%;
`

export const Label = styled(Text).attrs({ variant: 'label' })`
  padding: 15px 25px 15px 0px;
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: end;
`

export const ButtonWrapper = styled.div`
  padding-left: 20px;
`

export const FlexRowDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
