import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import reportWebVitals from './reportWebVitals'
import AppTheme from 'theme/AppTheme'
import { Provider } from 'react-redux'
import { store } from 'store/store'

import './i18n'
import { Auth0Provider } from '@auth0/auth0-react'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppTheme>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
          redirectUri={`${window.location.origin}`}
          audience={process.env.REACT_APP_AUTH0_AUDIENCE}
          organization={process.env.REACT_APP_AUTH0_ORG}
          cacheLocation="localstorage"
        >
          <App />
        </Auth0Provider>
      </AppTheme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
