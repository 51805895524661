import { Title, Text, Button } from 'tendo-ui'
import { Table } from 'antd'
import styled from 'styled-components'
import * as Styled from '../Styles'
import { ColumnsType } from 'antd/lib/table'
import { ResourceType } from 'types/types'
import { TableRowSelection } from 'antd/lib/table/interface'
import { useCallback, useMemo } from 'react'

export const DefaultRenderText = (text: string) => (
  <Styled.Label>
    <Text variant="body">{text}</Text>
  </Styled.Label>
)

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 30px 0px;
`

export interface ResourceTableProps {
  title: string
  loading?: boolean
  dataSource: ResourceType[]
  columns: ColumnsType<ResourceType>
  rowSelection?: TableRowSelection<ResourceType>
  footer?(): React.ReactNode
  footerButtons?(): React.ReactNode
  header?(): React.ReactNode
  refreshResource?(): void
  rowKeySelector?(t: ResourceType): string
}

const ResourceTable = (props: ResourceTableProps) => {
  const { header, footer, footerButtons, refreshResource } = props

  const memoHeader = useMemo(
    () => <Styled.Header>{header ? header() : ''}</Styled.Header>,
    [header]
  )

  const memoFooter = useCallback(
    () => (
      <>
        <div>
          {footer ? footer() : ''}
          <Styled.Label>
            <Text variant="label">Press Reload to load the first time.</Text>
          </Styled.Label>
        </div>
        <InputWrapper>
          {footerButtons ? footerButtons() : ''}
          <Styled.ButtonWrapper>
            <Button color="grey" key="1" onClick={refreshResource}>
              Reload
            </Button>
          </Styled.ButtonWrapper>
        </InputWrapper>
      </>
    ),
    [footer, footerButtons, refreshResource]
  )

  return (
    <Styled.Content>
      <Title size={25} level={1}>
        {props.title}
      </Title>
      <Styled.FlexRowDiv>{memoHeader}</Styled.FlexRowDiv>
      <Table
        dataSource={props.dataSource}
        columns={props.columns}
        rowSelection={props.rowSelection}
        tableLayout={'fixed'}
        footer={memoFooter}
        loading={props.loading}
        rowKey={props.rowKeySelector}
        pagination={{ pageSize: 25 }}
        scroll={{ y: 600, x: true }}
      />
    </Styled.Content>
  )
}

export default ResourceTable
