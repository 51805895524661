import { Modal, DatePicker } from 'antd'
import moment, { Moment } from 'moment'
import { useState } from 'react'
import styled from 'styled-components'
import { UilMinusCircle } from '@iconscout/react-unicons'
import { Title, Text, Select, SelectOption, Input, Tag, Button } from 'tendo-ui'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { CreateAthenaSlots } from 'service/ServiceApi'
import { toggleModalVisibility } from 'store/modalReducer'
import * as Styled from '../Styles'

const StyledModal = styled(Modal).attrs({ width: '400px' })`
  && {
    margin: 100;
    top: 50;
    max-width: unset;
    z-index: 3;
  }
`

const PaddingWrapper = styled.div`
  padding: 25px 0px 25px;
`

export interface CreateSlotsModalProps {
  destroyOnClose?: boolean
}

const dateFormat = 'MM/DD/YYYY'

const CreateSlotsModal = (props: CreateSlotsModalProps) => {
  const [slotTimes, setSlotTimes] = useState<string[]>([])
  const [dateTime, setDateTime] = useState<Moment>(moment())
  const [currentTime, setCurrentTime] = useState<string>('')
  const [providerID, setProviderID] = useState<string>('')
  const [departmentID, setDepartmentID] = useState<string>('')

  const dispatch = useAppDispatch()

  const departmentids = useAppSelector((state) =>
    state.resources.departments
      ? state.resources.departments.map((department) => department.DepartmentId)
      : []
  )
  const providerids = useAppSelector((state) =>
    state.resources.providers
      ? state.resources.providers.map((provider) => provider.external_id)
      : []
  )

  const visible = useAppSelector(
    (state) => state.modalVisibility.createSlotsVisible
  )

  const onClose = () => {
    dispatch(toggleModalVisibility('createSlotsVisible'))
  }

  const onDateSelect = (date: Moment | null, dateString: string) => {
    if (date) {
      setDateTime(date)
    }
    console.log(dateString)
  }

  const getTagOnClick = (time: string) => {
    return () => setSlotTimes(slotTimes.filter((t) => t !== time))
  }

  const addTime = () => {
    // A little bit of validation
    if (currentTime.length === 5 && currentTime.includes(':')) {
      setSlotTimes([...slotTimes, currentTime])
    }
    setCurrentTime('')
  }

  const onSubmit = () => {
    const slotParams = {
      times: slotTimes,
      providerid: providerID,
      departmentid: departmentID,
      date: dateTime.format(dateFormat),
    }

    CreateAthenaSlots(slotParams, onClose)
  }

  const okButtonDisabled = () => {
    if (slotTimes.length === 0 || departmentID === '' || providerID === '') {
      return true
    }
    return false
  }

  return (
    <StyledModal
      visible={visible}
      closable={true}
      onCancel={onClose}
      okText={'Create Slots'}
      cancelButtonProps={{ shape: 'round' }}
      okButtonProps={{ disabled: okButtonDisabled(), shape: 'round' }}
      onOk={onSubmit}
      {...props}
    >
      <Styled.Content>
        <Title level={1}>Create Slots</Title>
      </Styled.Content>
      <div>
        <PaddingWrapper>
          <Text variant="label">Provider ID</Text>
          <Select
            value={providerID}
            placeholder={''}
            onChange={(val) => setProviderID(String(val))}
          >
            {providerids.map((id, idx) => (
              <SelectOption key={idx} value={id}>
                {id}
              </SelectOption>
            ))}
          </Select>
        </PaddingWrapper>
        <PaddingWrapper>
          <Text variant="label">Department ID</Text>
          <Select
            value={departmentID}
            placeholder={''}
            onChange={(val) => setDepartmentID(String(val))}
          >
            {departmentids.map((id, idx) => (
              <SelectOption key={idx} value={id}>
                {id}
              </SelectOption>
            ))}
          </Select>
        </PaddingWrapper>
        <PaddingWrapper>
          <Text variant="label">Select a Date</Text>
          <DatePicker
            defaultPickerValue={moment()}
            picker="date"
            onChange={onDateSelect}
            format={dateFormat}
          />
        </PaddingWrapper>
        <PaddingWrapper>
          <Text variant="label">Times</Text>
          {slotTimes.map((time, idx) => (
            <Tag shape="pill" color="blue" key={idx}>
              {time}
              <UilMinusCircle size={10} onClick={getTagOnClick(time)} />
            </Tag>
          ))}
          <Input
            value={currentTime || ''}
            onChange={(e) => setCurrentTime(String(e.currentTarget.value))}
          />
          <PaddingWrapper>
            <Button onClick={addTime}>Add Time</Button>
          </PaddingWrapper>
        </PaddingWrapper>
      </div>
    </StyledModal>
  )
}

export default CreateSlotsModal
