import { Suspense, useContext, useState, useEffect } from 'react'
import { Auth0Context, useAuth0 } from '@auth0/auth0-react'
import { Menu } from 'antd'
import * as Styled from './Styles'
import Toolbar from 'components/Toolbar'
import Patients from 'components/Patients'
import Appointments from 'components/Appointments'
import Departments from 'components/Departments'
import Providers from 'components/Providers'
import Encounters from 'components/Encounters'
import QuestionnaireResponses from 'components/QuestionnaireResponse'
import Slots from 'components/Slots'
import { Text, Title } from 'tendo-ui'
import CreateSlotsModal from 'components/CreateSlotsModal'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import * as API from './service/ServiceApi'
import {
  SetAppointmentsWithDispatch,
  SetDepartmentsWithDispatch,
  SetEncountersWithDispatch,
  SetPatientsWithDispatch,
  SetProvidersWithDispatch,
} from 'store/resourceReducer'
import { setAuthToken } from 'store/credentialsReducer'
import { useLazyGetAthenaSlotsQuery } from './service/ServiceApi'

type ResourceKey =
  | 'patients'
  | 'appointments'
  | 'departments'
  | 'providers'
  | 'encounters'
  | 'questionnaireResponses'
  | 'slots'

type ResourceDescription = [ResourceKey, string]

const resources: ResourceDescription[] = [
  ['patients', 'Patients'],
  ['appointments', 'Appointments'],
  ['departments', 'Departments'],
  ['providers', 'Providers'],
  ['encounters', 'Encounters'],
  ['questionnaireResponses', 'QuestionnaireResponses'],
  ['slots', 'Slots'],
]

const ResourceDisplay = (): JSX.Element => {
  const [resourceKey, setResourceKey] = useState<ResourceKey>('patients')
  const dispatch = useAppDispatch()

  const onClickItemBuilder = (key: ResourceKey) => () => setResourceKey(key)
  const [refetchSlots] = useLazyGetAthenaSlotsQuery()

  const loadStore = () => {
    API.GetAthenaDepartments(SetDepartmentsWithDispatch(dispatch))
    API.GetAthenaAppointments(SetAppointmentsWithDispatch(dispatch))
    API.GetAthenaEncounters(SetEncountersWithDispatch(dispatch))
    API.GetAthenaPatients(SetPatientsWithDispatch(dispatch))
    refetchSlots('')
    API.GetKyruusProviders(SetProvidersWithDispatch(dispatch))
  }

  const getContent = () => {
    switch (resourceKey) {
      case 'patients':
        return <Patients />
      case 'appointments':
        return <Appointments />
      case 'departments':
        return <Departments />
      case 'providers':
        return <Providers />
      case 'encounters':
        return <Encounters />
      case 'questionnaireResponses':
        return <QuestionnaireResponses />
      case 'slots':
        return (
          <>
            <Slots />
            <CreateSlotsModal key={'slots'} destroyOnClose={true} />
          </>
        )
    }
  }

  return (
    <Styled.Wrapper>
      <Menu selectedKeys={[resourceKey]} mode="horizontal">
        {resources.map((resourceDesc) => (
          <Menu.Item
            key={resourceDesc[0]}
            onClick={onClickItemBuilder(resourceDesc[0])}
          >
            {resourceDesc[1]}
          </Menu.Item>
        ))}
        <Menu.Item
          style={{ marginLeft: 'auto' }}
          key="reload"
          onClick={loadStore}
        >
          <Text variant="body" style={{ paddingTop: 10 }}>
            Reload Store
          </Text>
        </Menu.Item>
      </Menu>
      <div>{getContent()}</div>
    </Styled.Wrapper>
  )
}

export default function App(): JSX.Element {
  const auth0Context = useContext(Auth0Context)
  if (!auth0Context) {
    // useUser required Auth0Provider (useAuth0)
    throw new Error(`useUser must be used within a Auth0Provider`)
  }

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()
  const dispatch = useAppDispatch()
  const authToken = useAppSelector((state) => state.credentials.authToken)

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !authToken) {
      loginWithRedirect()
    }
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          API.setAxiosAuthorization(token)
          dispatch(setAuthToken({ authToken: token }))
        })
        .catch(async (error) => {
          console.log('error')
          if (error?.status === 401) {
            await loginWithRedirect()
          }
        })
    }
  }, [
    isLoading,
    loginWithRedirect,
    isAuthenticated,
    getAccessTokenSilently,
    dispatch,
    authToken,
  ])

  if (!isLoading && !isAuthenticated && !authToken) {
    return (
      <Suspense fallback="loading">
        <Styled.Wrapper>
          <Title size={35} level={1}>
            Refreshing Auth Token
          </Title>
        </Styled.Wrapper>
      </Suspense>
    )
  }

  return (
    <Suspense fallback="loading">
      {!isLoading ? (
        <div>
          <Toolbar name={`SCARF`} />
          <ResourceDisplay />
        </div>
      ) : (
        <Styled.Wrapper>
          <Title size={35} level={1}>
            Loading
          </Title>
        </Styled.Wrapper>
      )}
    </Suspense>
  )
}
