import axios, {
  AxiosRequestConfig,
} from 'axios' /* eslint-disable @typescript-eslint/ban-types */
import {
  AthenaV1Department,
  KyruusV9ProviderSearchResponse,
  AthenaV1Patient,
  AthenaV1Slot,
  AthenaV1Appointment,
  AthenaV1Encounter,
} from 'types/types'
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import type { RootState } from '../store/store'

export const BASE_URL = process.env.REACT_APP_API_URL
axios.defaults.baseURL = BASE_URL

// TODO update this to application/json
axios.defaults.headers.common['Accept'] = '*/*'

const ATHENA_BASE_URL = '/athena/v1'
const KYRUUS_BASE_URL = '/kyruus/v9'

export const setAxiosAuthorization = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// Just a simple logging for now but this could grow
export const errorHandler = (error: unknown): void => {
  if (process.env.NODE_ENV === 'development') {
    console.error('API ERROR: ', error)
  }
}

const patientsUrl = `${ATHENA_BASE_URL}/patients`
export function GetAthenaPatients(
  onComplete: (patients: AthenaV1Patient[]) => void,
  onFailure?: () => void
): void {
  axios
    .get<AthenaV1Patient[]>(patientsUrl)
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

const encountersUrl = `${ATHENA_BASE_URL}/chart/encounters`
export function GetAthenaEncounters(
  onComplete: (encounters: AthenaV1Encounter[]) => void,
  onFailure?: () => void
): void {
  axios
    .get<AthenaV1Encounter[]>(encountersUrl)
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

const appointmentsUrl = `${ATHENA_BASE_URL}/appointments`
export function GetAthenaAppointments(
  onComplete: (encounters: AthenaV1Appointment[]) => void,
  onFailure?: () => void
): void {
  axios
    .get<AthenaV1Appointment[]>(appointmentsUrl)
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

export interface DepartmentResponse {
  totalcount: number
  departments: AthenaV1Department[]
}

const departmentsUrl = `${ATHENA_BASE_URL}/departments`
export function GetAthenaDepartments(
  onComplete: (response: DepartmentResponse) => void,
  onFailure?: () => void
): void {
  axios
    .get<DepartmentResponse>(departmentsUrl)
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

export interface SlotRequestParams {
  departmentid?: number
  providerid?: number
}

export interface SlotResponse {
  totalcount: number
  appointments: AthenaV1Slot[]
}

const slotsUrl = `${ATHENA_BASE_URL}/appointments/open`
const DEFAULT_DEPARTMENT_ID = -1

export const athenaAPI = createApi({
  reducerPath: 'getAthenaSlots',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${BASE_URL}${ATHENA_BASE_URL}`,
      prepareHeaders: (headers, { getState }) => {
        const authToken = (getState() as RootState).credentials.authToken
        headers.set('Authorization', `Bearer ${authToken}`)
        headers.set('Content-Type', 'application/x-www-form-urlencoded')
        headers.set('Accept', 'application/json')
        return headers
      },
    }),
    {
      maxRetries: 2,
    }
  ),
  endpoints: (builder) => ({
    getAthenaSlots: builder.query<SlotResponse, string>({
      query: () => `/appointments/open?departmentid=-1`,
    }),
  }),
})

export const { useLazyGetAthenaSlotsQuery } = athenaAPI

export function GetAthenaSlots(
  slotRequestParams: SlotRequestParams,
  onComplete: (response: SlotResponse) => void,
  onFailure?: () => void
): void {
  let params = {}
  if (slotRequestParams.departmentid && slotRequestParams.providerid) {
    params = {
      departmentid: slotRequestParams.departmentid,
      providerid: slotRequestParams.providerid,
    }
  } else if (slotRequestParams.departmentid) {
    params = {
      departmentid: slotRequestParams.departmentid,
    }
  } else if (slotRequestParams.providerid) {
    params = {
      departmentid: DEFAULT_DEPARTMENT_ID,
      providerid: slotRequestParams.providerid,
    }
  } else {
    params = {
      departmentid: DEFAULT_DEPARTMENT_ID,
    }
  }

  axios
    .get<SlotResponse>(slotsUrl, { params })
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

interface CreateSlotsParams {
  times: string[]
  providerid: string
  departmentid: string
  date: string
}

export function CreateAthenaSlots(
  params: CreateSlotsParams,
  onComplete: () => void,
  onFailure?: () => void
): void {
  const requestConfig: AxiosRequestConfig<FormData> = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }

  const data = new URLSearchParams()
  data.append('departmentid', params.departmentid)
  data.append('providerid', params.providerid)
  data.append('reasonid', '1281')
  data.append('appointmentdate', params.date)
  for (const time of params.times) {
    data.append('appointmenttime', time)
  }

  axios
    .post(slotsUrl, data, requestConfig)
    .then(() => {
      onComplete()
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

const providersUrl = `${KYRUUS_BASE_URL}/providers`
export function GetKyruusProviders(
  onComplete: (response: KyruusV9ProviderSearchResponse) => void,
  onFailure?: () => void
): void {
  axios
    .get<KyruusV9ProviderSearchResponse>(providersUrl, {
      params: {
        per_page: 100,
      },
    })
    .then((response) => {
      if (response && response.data) {
        onComplete(response.data)
      }
    })
    .catch((err) => {
      errorHandler(err)
      if (onFailure) {
        onFailure()
      }
    })
}

const deleteUrl = '/athena/v1/batch/delete'
export function AthenaDeleteIDs(
  resourceType: string,
  ids: string[],
  onFinish?: () => void
) {
  const requestConfig: AxiosRequestConfig<FormData> = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  }

  const data = new URLSearchParams()
  data.append('resourcetype', resourceType)
  for (const id of ids) {
    data.append('resourceids', id)
  }

  axios
    .put(deleteUrl, data, requestConfig)
    .then(() => {
      if (onFinish) {
        onFinish()
      }
    })
    .catch(() => console.log('request failed'))
}
