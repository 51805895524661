import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalState {
  createSlotsVisible: boolean
}

const initialState: ModalState = {
  createSlotsVisible: false,
}

type ModalKey = keyof ModalState

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleModalVisibility: (state, action: PayloadAction<ModalKey>) => {
      state[action.payload] = !state[action.payload]
    },
    resetState: () => initialState,
  },
})

export const { toggleModalVisibility, resetState } = modalSlice.actions

export default modalSlice.reducer
