import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AthenaV1Department,
  KyruusV9Provider,
  KyruusV9ProviderSearchResponse,
  AthenaV1Patient,
  AthenaV1Appointment,
  AthenaV1Encounter,
} from 'types/types'
import { AppDispatch } from './store'
import * as API from '../service/ServiceApi'

interface ResourcesState {
  appointments: AthenaV1Appointment[]
  providers: KyruusV9Provider[]
  departments: AthenaV1Department[]
  encounters: AthenaV1Encounter[]
  patients: AthenaV1Patient[]
}

const initialState: ResourcesState = {
  appointments: [],
  providers: [],
  departments: [],
  encounters: [],
  patients: [],
}

export const resourceSlice = createSlice({
  name: 'resources',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDepartments: (
      state,
      action: PayloadAction<{
        totalcount: number
        departments: AthenaV1Department[]
      }>
    ) => {
      state.departments = action.payload.departments
    },
    setProviders: (
      state,
      action: PayloadAction<KyruusV9ProviderSearchResponse>
    ) => {
      if (action.payload._result) {
        state.providers = action.payload._result.map(
          (providerSearch) => providerSearch.provider
        )
      }
    },
    setAppointments: (state, action: PayloadAction<AthenaV1Appointment[]>) => {
      state.appointments = action.payload
    },
    setEncounters: (state, action: PayloadAction<AthenaV1Encounter[]>) => {
      state.encounters = action.payload
    },

    setPatients: (state, action: PayloadAction<AthenaV1Patient[]>) => {
      state.patients = action.payload
    },
    resetState: () => initialState,
  },
})

export const {
  setDepartments,
  setProviders,
  setAppointments,
  setEncounters,
  setPatients,
  resetState,
} = resourceSlice.actions

type SetDepartmentsWithDispatchReturnType = (
  response: API.DepartmentResponse
) => void
export const SetDepartmentsWithDispatch = (
  dispatch: AppDispatch
): SetDepartmentsWithDispatchReturnType => {
  return (response: API.DepartmentResponse) =>
    dispatch(setDepartments(response))
}

type SetAppointmentsWithDispatchReturnType = (
  response: AthenaV1Appointment[]
) => void
export const SetAppointmentsWithDispatch = (
  dispatch: AppDispatch
): SetAppointmentsWithDispatchReturnType => {
  return (response: AthenaV1Appointment[]) =>
    dispatch(setAppointments(response))
}

type SetProvidersWithDispatchReturnType = (
  response: KyruusV9ProviderSearchResponse
) => void
export const SetProvidersWithDispatch = (
  dispatch: AppDispatch
): SetProvidersWithDispatchReturnType => {
  return (response: KyruusV9ProviderSearchResponse) =>
    dispatch(setProviders(response))
}

type SetEncountersWithDispatchReturnType = (
  response: AthenaV1Encounter[]
) => void
export const SetEncountersWithDispatch = (
  dispatch: AppDispatch
): SetEncountersWithDispatchReturnType => {
  return (response: AthenaV1Encounter[]) => dispatch(setEncounters(response))
}

type SetPatientsWithDispatchReturnType = (response: AthenaV1Patient[]) => void
export const SetPatientsWithDispatch = (
  dispatch: AppDispatch
): SetPatientsWithDispatchReturnType => {
  return (response: AthenaV1Patient[]) => dispatch(setPatients(response))
}

export default resourceSlice.reducer
