import { combineReducers } from 'redux'
import modalReducer from './modalReducer'
import resourceReducer from './resourceReducer'
import credentialsReducer from './credentialsReducer'
import { athenaAPI } from '../service/ServiceApi'

export const rootReducer = combineReducers({
  resources: resourceReducer,
  modalVisibility: modalReducer,
  credentials: credentialsReducer,
  [athenaAPI.reducerPath]: athenaAPI.reducer,
})
